import Vue from "vue";
import VueI18n from "vue-i18n";
import VueI18nFilter from "vue-i18n-filter";
import axios from "axios";

Vue.use(VueI18n);
Vue.use(VueI18nFilter);

const TRANSLATION_ENDPOINT = "https://raw.githubusercontent.com/AvilonRobotics/aviloniot-content/main/translate.json";

const messages = {
    en: {
        Home: "Home",
        SignInHeader: "Sign in to your account",
        "Aerator settings": "Settings",
    },
    // th: {
    //     Home: "หน้าหลัก",
    //     SignInHeader: "ลงชื่อเข้าใช้",
    //     Documentation: "คู่มือ",
    //     "Contact us": "ติดต่อเรา",
    //     "Welcome to": "ยินดีต้อนรับ",
    //     AppName: "Watercare",
    //     Profile: "โปรไฟล์",
    //     "Sign in": "ลงชื่อเข้าใช้",
    //     "Sign out": "ออกจากระบบ",
    //     Actions: "เมนู",
    //     "Connection Status": "สถานะการเชื่อมต่อ",
    //     "Alert Status": "สถานะการแจ้งเตือน",
    //     "View details": "รายละเอียด",
    //     Share: "แชร์",
    //     Remove: "ลบ",
    //     "Add device": "เพิ่มเซ็นเชอร์",
    //     "Choose add sensor method": "เลือกวิธีเพิ่มเซ็นเซอร์",
    //     "Weather Forecast": "พยากรณ์อากาศ",
    //     Humidity: "ความชื้น",
    //     Temperature: "อุณหภูมิ",
    //     Notifications: "การแจ้งเตือน",
    //     Dashboard: "แดชบอร์ด",
    //     "Add Line notify": "เพิ่ม Line notify",
    //     "Add rule type": "เพิ่มรูปแบบการแจ้งเตือน",
    //     Rules: "รูปแบบการแจ้งเตือน",
    //     "Add rule": "เพิ่ม",
    //     "Add notification": "เพิ่ม",
    //     "No new message": "ไม่มีข้อความใหม่",
    //     TempWater: "อุณหภูมิน้ำ",
    //     TempAir: "อุณหภูมิอากาศ",
    //     Enable: "เปิด/ปิด",
    //     "Alert to:": "ส่งไปที่",
    //     "Field:": "ค่า",
    //     Alert: "แจ้งเตือน",
    //     "Thing ID:": "เซ็นเซอร์",
    //     "Select thing ID": "เลือกเซ็นเซอร์",
    //     "Select field": "เลือกค่าที่ต้องการ",
    //     "Select method": "เลือกวิธีการตรวจจับค่า",
    //     "Select alert": "ส่งแจ้งเตือนไปที่",
    //     "Aerator status": "สถานะเครื่องเติมอากาศ",
    //     "Aerator settings": "ตั้งค่าเครื่องเติมอากาศ",
    //     "Start aerator when DO less than":
    //         "เปิดเครื่องเติมอากาศเมื่อค่า DO ตำ่กว่า",
    //     "Stop aerator when DO more than":
    //         "ปิดเครื่องเติมอากกาศเมื่อค่า DO สูงกว่า",
    //     "Warn when DO lower than": "แจ้งเตือนเมื่อค่า DO ต่ำกว่า",
    //     On: "เปิด",
    //     Off: "ปิด",
    //     Auto: "อัตโนมัติ",
    // },
};

const loadedLanguages: Array<string> = [];

const i18n = new VueI18n({
    locale: "th", // set locale
    fallbackLocale: "en", // set fallback locale
    messages, // set locale messages
});

function setI18nLanguage(lang: string) {
    i18n.locale = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document?.querySelector("html")?.setAttribute("lang", lang);
    return lang;
}

export function loadLanguageAsync(lang: string): Promise<void> {
    if (loadedLanguages.includes(lang)) {
        if (i18n.locale !== lang) {
            setI18nLanguage(lang);
        }
        return Promise.resolve();
    }

    return axios.get(TRANSLATION_ENDPOINT).then((response) => {
        const msgs = response.data;
        console.log({msgs})
        loadedLanguages.push(lang);
        console.log(lang, msgs)
        i18n.setLocaleMessage(lang, msgs[lang]);
        setI18nLanguage(lang);
    });
}

export default i18n;
