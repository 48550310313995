import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Amplify, { Hub, Auth } from "aws-amplify";
import aws_exports from "./aws-exports.js";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import "./amplify-i18n";

import Buefy from "buefy";
// import 'buefy/dist/buefy.css'
import "@/styles/style.scss";

import "@/main.css";

import { library } from "@fortawesome/fontawesome-svg-core";
// internal icons
import {
  faBell,
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faAngleUp,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCaretUp,
  faUpload,
  faDownload,
  faExternalLinkAlt,
  faPlus,
  faCog,
  faTachometerAlt,
  faEllipsisV,
  faMinus,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";

import { faLine } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

console.log({ faLine });
library.add(
  faBell,
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faAngleUp,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCaretUp,
  faUpload,
  faDownload,
  faExternalLinkAlt,
  faLine,
  faPlus,
  faMinus,
  faCog,
  faTachometerAlt,
  faEllipsisV,
  faCalendarDay
);
Vue.component("vue-fontawesome", FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
});

import i18n from "./locale";

import VueResizeObserver from "vue-resize-observer";
Vue.use(VueResizeObserver);

import VueResizeText from "vue-resize-text";
Vue.use(VueResizeText);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

console.log("aws_exports: ", aws_exports);

Amplify.configure(aws_exports);

import { InfluxDB } from "@influxdata/influxdb-client";
const INFLUX_ENDPOINT = process.env.VUE_APP_INFLUX_ENDPOINT || "";
const INFLUX_ALERT_ENDPOINT = process.env.VUE_APP_INFLUX_ALERT_ENDPOINT || "";
const ORG_NAME = process.env.VUE_APP_ORG_NAME || "";

Vue.prototype.$THING_CREDENTIAL = process.env.VUE_APP_THING_CREDENTIAL;
Vue.prototype.$MQTT = process.env.VUE_APP_MQTT;
Vue.prototype.$INFLUX_ENDPOINT = INFLUX_ENDPOINT;
Vue.prototype.$INFLUX_ALERT_ENDPOINT = INFLUX_ALERT_ENDPOINT;
Vue.prototype.$INFLUX_DEFAULT_DB = "AVILON_IOT";

Vue.prototype.$QUERY_API = new InfluxDB({
  url: INFLUX_ENDPOINT,
  token: undefined,
}).getQueryApi(ORG_NAME);

Vue.config.productionTip = false;

Hub.listen("auth", async (data) => {
  console.log("auth event:", data);
  switch (data.payload.event) {
    case "signIn":
      {
        console.log("sign in!");
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          router.push({
            name: "Profile",
            params: {
              username: user.username,
            },
          });
        }
      }
      break;
    case "signIn_failure":
      console.log("sign in failure!");
      break;
    default:
      break;
  }
});

import service from "@/service";
Vue.prototype.$service = service;

service.getDeviceConfiguration();

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
