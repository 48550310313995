export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      language
      things {
        items {
          id
          userID
          thingID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      thingGroups {
        items {
          id
          userID
          thingGroupID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          themeColor
          userID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          input
          rule
          parameter
          description
          running
          userID
          output
          createdAt
          updatedAt
        }
        nextToken
      }
      alerts {
        items {
          id
          userID
          type
          name
          token
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getAlert = /* GraphQL */ `
  query GetAlert($id: ID!) {
    getAlert(id: $id) {
      id
      userID
      type
      name
      token
      enable
      tasks {
        items {
          id
          taskID
          alertID
          alert {
            items {
              id
              thingID
              AnomalyDetectorID
              topic
              field
              enable
              method
              sensitivity
              upperBound
              lowerBound
              threshold
              createdAt
              updatedAt
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listAlertByUserId = /* GraphQL */ `
  query ListAlertByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlertByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        name
        token
        enable
        tasks {
          items {
            id
            task {
              id
              thingID
              topic
              field
              enable
              method
              sensitivity
              upperBound
              lowerBound
              threshold
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listThingTaskByUserId = /* GraphQL */ `
query ListThingTaskByUserId(
  $userID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelThingTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listThingTaskByUserID(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serverID
      type
      enable
      thingID
      userID
      topic
      alerts {
        items {
          taskID 
          alertID
          alert {
            id
            name
          }
        }
        nextToken
      }
      field
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      createdAt
      updatedAt
    }
    nextToken
  }
}`