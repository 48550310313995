import { loadLanguageAsync } from "@/locale";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import multiguard from "vue-router-multiguard";
// import Home from "../views/Home.vue";
import Home from "../views/V2/Home.vue";
import { authenticationGuard } from "./guard";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/profile/:username",
    name: "Profile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    children: [
      {
        // NOTE default child must has the same name as parent
        name: "Profile",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/Profile/ProfileRoot.vue"
          ),
      },
      {
        name: "ProfileDevice",
        path: "device/:thingid",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/Profile/ProfileDevice.vue"
          ),
      },
      {
        name: "ProfileAddSensorInstruction",
        path: "addsensor-intro",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/Profile/ProfileAddSensorInstruction.vue"
          ),
      },
      {
        name: "ProfileAddSensorByQRCode",
        path: "addsensor-qrcode",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/Profile/ProfileAddSensorByQRCode.vue"
          ),
      },
      {
        name: "ProfileNotifications",
        path: "notifications",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/Profile/ProfileNotifications.vue"
          ),
      },
      // {
      //     // UserProfile will be rendered inside User's <router-view>
      //     // when /user/:id/profile is matched
      //     path: "profile",
      //     component: UserProfile,
      // },
      // {
      //     // UserPosts will be rendered inside User's <router-view>
      //     // when /user/:id/posts is matched
      //     path: "posts",
      //     component: UserPosts,
      // },
    ],
    beforeEnter: multiguard([authenticationGuard]),
  },
  {
    path: "/line-notify-redirect",
    name: "LineNotifyRedirect",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/LineNotifyRedirect.vue"),
  },
  {
    path: "/auth",
    name: "Auth",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/V2/Authentication.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // const lang = to.params.lang;
  const lang = "th";
  loadLanguageAsync(lang).then(() => next());
});

export default router;
