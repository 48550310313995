import axios from "axios";

async function onecall(
    lat = 13.751330328,
    lon = 100.489664708,
    exclude = "minutely",
    units = "metric",
    lang = "th"
) {
    return (await axios.get("https://api.openweathermap.org/data/2.5/onecall", {
        params: {
            lat,
            lon,
            exclude,
            units,
            lang,
            appid: process.env.VUE_APP_OPEN_WEATHER_MAP_APPID,
        },
    })).data;
}

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async get(context, payload) {
        if (payload) {
            return onecall(payload.lat, payload.lon, payload.exclude);
        }
        return onecall();
    },
};

const weatherForecast = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default weatherForecast;
