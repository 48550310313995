<template>
  <section class="hero is-primary is-fullheight">
    <div class="present">
      <figure class="image">
        <img width="500px" :src="logo" />
      </figure>
      <div class="text">
        <h1>WaterCare</h1>
        <h5>เราดูแลมากกว่าน้ำ</h5>
      </div>
    </div>
    <button
      v-if="state === 2"
      @click.prevent="onProfile"
      class="button is-rounded is-white is-outlined"
    >
      เข้าสู่ระบบ
    </button>
  </section>
</template>

<script>
import Auth from "@aws-amplify/auth";

export default {
  name: "Home",
  data() {
    return {
      logo: require("@/assets/v2/logo/water_recovered.png"),
      state: 1,
    };
  },
  mounted() {
    setTimeout(() => {
      this.state = 2;
    }, 2000);
  },
  methods: {
    async onProfile() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          this.$router.push({
            name: "Profile",
            params: {
              username: user.username,
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.$router.replace({ path: "/auth" });
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 80px;
}

h5 {
  font-size: 30px;
}

.image {
  width: 250px;
  transform: scale(2);
}

.present {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button {
  width: 200px;
  font-size: 26px;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  animation-name: button-animation;
  animation-duration: 0.3s;
}

@media screen and (max-width: 599px) {
  h1 {
    font-size: 46px;
  }

  h5 {
    font-size: 22px;
  }
  .image {
    margin: 20px;
  }

  .present {
    display: flex;
    flex-direction: column;
  }
}

@keyframes button-animation {
  0% {
    bottom: 0%;
  }
  100% {
    bottom: 10%;
  }
}
</style>
