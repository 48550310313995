import { API } from "aws-amplify";
import * as query from "@/graphql/queries";
import * as mutation from "@/graphql/mutations";

const state = {
    alertStream: {},
    counter: 0,
};

const getters = {};

const mutations = {
    setAlertStream: (state, {thingID, alertList}) => {
        const previousdState = state;
        previousdState.alertStream[thingID] = alertList;
        state.alertStream = previousdState.alertStream;
        state.counter = state.counter + 1;
    },
};

const actions = {
    async getAlertStream(context: any, { thingID }) {
        const response: any = await API.graphql({
            query: query.listAlertStreamByThingAndCreatedAt,
            variables: {
                thingID,
                limit: 1,
                sortDirection: "DESC"
            },
        });
        const alertList =
            response.data.listAlertStreamByThingAndCreatedAt.items;

        context.commit("setAlertStream", {
            thingID,
            alertList,
        });
    },
};

const alert = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default alert;
