import { API } from "aws-amplify";
import * as query from "@/graphql/queries";
import * as mutation from "@/graphql/mutations";

const state = {
  user: {},
};

const getters = {
  dashboardList: (state) => {
    return state.user?.dashboards?.items || [];
  },
  thingList: (state) => {
    return state.user?.things?.items || [];
  },
  alertList: (state) => {
    return state.user?.alerts?.items || [];
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
};

const actions = {
  async createUser(context, { id, name, language = "en" }) {
    const response: any = await API.graphql({
      query: mutation.createUser,
      variables: {
        input: {
          id,
          name,
          language,
        },
      },
    });
    const { data } = response;
    if (data) {
      const { createUser } = data;
      return createUser;
    }
  },
  async fetchUser(context, { username, name }) {
    const response: any = await API.graphql({
      query: query.getUser,
      variables: {
        id: username,
      },
    });
    const { data } = response;
    if (data) {
      const { getUser } = data;
      if (getUser) {
        return getUser;
      } else {
        return await context.dispatch("createUser", {
          id: username,
          name,
        });
      }
    }
  },
  async updateWeatherLocation(context, { lat, lon, displayName }) {
    const response: any = await API.graphql({
      query: mutation.updateUser,
      variables: {
        input: {
          id: context.state.user.id,
          weatherLocation: {
            lat,
            lon,
            displayName,
          },
        },
      },
    });
    context.commit("setUser", response.data.updateUser);
  },
};

const user = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default user;
