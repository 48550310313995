import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";

I18n.putVocabulariesForLanguage("en-US", {
    [Translations.BACK_TO_SIGN_IN]: 'Back to Sign In',
	[Translations.CHANGE_PASSWORD_ACTION]: 'Change',
	[Translations.CHANGE_PASSWORD]:'Change Password',
	[Translations.CODE_LABEL]:'Verification code',
	[Translations.CODE_PLACEHOLDER]:'Enter code',
	[Translations.CONFIRM_SIGN_UP_CODE_LABEL]:'Confirmation Code',
	[Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]:'Enter your code',
	[Translations.CONFIRM_SIGN_UP_HEADER_TEXT]:'Confirm Sign up',
	[Translations.CONFIRM_SIGN_UP_LOST_CODE]:'Lost your code?',
	[Translations.CONFIRM_SIGN_UP_RESEND_CODE]:'Resend Code',
	[Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]:'Confirm',
	[Translations.CONFIRM_SMS_CODE]:'Confirm SMS Code',
	[Translations.CONFIRM_TOTP_CODE]:'Confirm TOTP Code',
	[Translations.CONFIRM]:'Confirm',
	[Translations.CREATE_ACCOUNT_TEXT]:'Create account',
	[Translations.EMAIL_LABEL]:'Email Address *',
	[Translations.EMAIL_PLACEHOLDER]:'Enter your email address',
	[Translations.FORGOT_PASSWORD_TEXT]:'Forgot your password?',
	[Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]:'Less than two MFA types available',
	[Translations.NEW_PASSWORD_LABEL]:'New password',
	[Translations.NEW_PASSWORD_PLACEHOLDER]:'Enter your new password',
	[Translations.NO_ACCOUNT_TEXT]:'No account?',
	[Translations.USERNAME_REMOVE_WHITESPACE]:'Username cannot contain whitespace',
	[Translations.PASSWORD_REMOVE_WHITESPACE]:'Password cannot start or end with whitespace',
	[Translations.PASSWORD_LABEL]:'Password *',
	[Translations.PASSWORD_PLACEHOLDER]:'Enter your password',
	[Translations.PHONE_LABEL]:'Phone Number *',
	[Translations.PHONE_PLACEHOLDER]:'(555) 555-1212',
	[Translations.QR_CODE_ALT]:'qrcode',
	[Translations.RESET_PASSWORD_TEXT]:'Reset password',
	[Translations.RESET_YOUR_PASSWORD]:'Reset your password',
	[Translations.SELECT_MFA_TYPE_HEADER_TEXT]:'Select MFA Type',
	[Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]:'Verify',
	[Translations.SEND_CODE]:'Send Code',
	[Translations.SUBMIT]:'Submit',
	[Translations.SETUP_TOTP_REQUIRED]:'TOTP needs to be configured',
	[Translations.SIGN_IN_ACTION]:'Sign In',
	[Translations.SIGN_IN_HEADER_TEXT]:'Sign in to your account',
	[Translations.SIGN_IN_TEXT]:'Sign in',
	[Translations.SIGN_IN_WITH_AMAZON]:'Sign in with Amazon',
	[Translations.SIGN_IN_WITH_AUTH0]:'Sign in with Auth0',
	[Translations.SIGN_IN_WITH_AWS]:'Sign in with AWS',
	[Translations.SIGN_IN_WITH_FACEBOOK]:'Sign in with Facebook',
	[Translations.SIGN_IN_WITH_GOOGLE]:'Sign in with Google',
	[Translations.SIGN_OUT]:'Sign Out',
	[Translations.SIGN_UP_EMAIL_PLACEHOLDER]:'Email',
	[Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]:'Have an account?',
	[Translations.SIGN_UP_HEADER_TEXT]:'Create a new account',
	[Translations.SIGN_UP_PASSWORD_PLACEHOLDER]:'Password',
	[Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]:'Create Account',
	[Translations.SIGN_UP_USERNAME_PLACEHOLDER]:'Username',
	[Translations.SUCCESS_MFA_TYPE]:'Success! Your MFA Type is now]:',
	[Translations.TOTP_HEADER_TEXT]:'Scan then enter verification code',
	[Translations.TOTP_LABEL]:'Enter Security Code]:',
	[Translations.TOTP_ISSUER]:'AWSCognito',
	[Translations.TOTP_SETUP_FAILURE]:'TOTP Setup has failed',
	[Translations.TOTP_SUBMIT_BUTTON_TEXT]:'Verify Security Token',
	[Translations.TOTP_SUCCESS_MESSAGE]:'Setup TOTP successfully!',
	[Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]:'Failed! Unable to configure MFA at this time',
	[Translations.USERNAME_LABEL]:'Username *',
	[Translations.USERNAME_PLACEHOLDER]:'Enter your username',
	[Translations.VERIFY_CONTACT_EMAIL_LABEL]:'Email',
	[Translations.VERIFY_CONTACT_HEADER_TEXT]:'Account recovery requires verified contact information',
	[Translations.VERIFY_CONTACT_PHONE_LABEL]:'Phone Number',
	[Translations.VERIFY_CONTACT_SUBMIT_LABEL]:'Submit',
	[Translations.VERIFY_CONTACT_VERIFY_LABEL]:'Verify',
	[Translations.ADDRESS_LABEL]:'Address',
	[Translations.ADDRESS_PLACEHOLDER]:'Enter your address',
	[Translations.NICKNAME_LABEL]:'Nickname',
	[Translations.NICKNAME_PLACEHOLDER]:'Enter your nickname',
	[Translations.BIRTHDATE_LABEL]:'Birthday',
	[Translations.BIRTHDATE_PLACEHOLDER]:'Enter your birthday',
	[Translations.PICTURE_LABEL]:'Picture URL',
	[Translations.PICTURE_PLACEHOLDER]:'Enter your picture URL',
	[Translations.FAMILY_NAME_LABEL]:'Family Name',
	[Translations.FAMILY_NAME_PLACEHOLDER]:'Enter your family name',
	[Translations.PREFERRED_USERNAME_LABEL]:'Preferred Username',
	[Translations.PREFERRED_USERNAME_PLACEHOLDER]:'Enter your preferred username',
	[Translations.GENDER_LABEL]:'Gender',
	[Translations.GENDER_PLACEHOLDER]:'Enter your gender',
	[Translations.PROFILE_LABEL]:'Profile URL',
	[Translations.PROFILE_PLACEHOLDER]:'Enter your profile URL',
	[Translations.GIVEN_NAME_LABEL]:'First Name',
	[Translations.GIVEN_NAME_PLACEHOLDER]:'Enter your first name',
	[Translations.ZONEINFO_LABEL]:'Time zone',
	[Translations.ZONEINFO_PLACEHOLDER]:'Enter your time zone',
	[Translations.LOCALE_LABEL]:'Locale',
	[Translations.LOCALE_PLACEHOLDER]:'Enter your locale',
	[Translations.UPDATED_AT_LABEL]:'Updated At',
	[Translations.UPDATED_AT_PLACEHOLDER]:'Enter the time the information was last updated',
	[Translations.MIDDLE_NAME_LABEL]:'Middle Name',
	[Translations.MIDDLE_NAME_PLACEHOLDER]:'Enter your middle name',
	[Translations.WEBSITE_LABEL]:'Website',
	[Translations.WEBSITE_PLACEHOLDER]:'Enter your website',
	[Translations.NAME_LABEL]:'Full Name',
	[Translations.NAME_PLACEHOLDER]:'Enter your full name',
	[Translations.PHOTO_PICKER_TITLE]:'Picker Title',
	[Translations.PHOTO_PICKER_HINT]:'Ancillary text or content may occupy this space here',
	[Translations.PHOTO_PICKER_PLACEHOLDER_HINT]:'Placeholder hint',
	[Translations.PHOTO_PICKER_BUTTON_TEXT]:'Button',
	[Translations.IMAGE_PICKER_TITLE]:'Add Profile Photo',
	[Translations.IMAGE_PICKER_HINT]:'Preview the image before upload',
	[Translations.IMAGE_PICKER_PLACEHOLDER_HINT]:'Tap to image select',
	[Translations.IMAGE_PICKER_BUTTON_TEXT]:'Upload',
	[Translations.PICKER_TEXT]:'Pick a file',
	[Translations.TEXT_FALLBACK_CONTENT]:'Fallback Content',
	[Translations.CONFIRM_SIGN_UP_FAILED]:'Confirm Sign Up Failed',
	[Translations.SIGN_UP_FAILED]:'Sign Up Failed',
});

I18n.putVocabulariesForLanguage("th-TH", {
    [Translations.BACK_TO_SIGN_IN]: 'กลับ',
	[Translations.CHANGE_PASSWORD_ACTION]: 'Change',
	[Translations.CHANGE_PASSWORD]:'Change Password',
	[Translations.CODE_LABEL]:'Verification code',
	[Translations.CODE_PLACEHOLDER]:'Enter code',
	[Translations.CONFIRM_SIGN_UP_CODE_LABEL]:'รหัสยืนยัน',
	[Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]:'ใส่รหัสยืนยัน',
	[Translations.CONFIRM_SIGN_UP_HEADER_TEXT]:'ยืนยันการลงทะเบียน',
	[Translations.CONFIRM_SIGN_UP_LOST_CODE]:'ไม่ได้รับรหัส?',
	[Translations.CONFIRM_SIGN_UP_RESEND_CODE]:'ส่งรหัสอีกครั้ง',
	[Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]:'ยืนยัน',
	[Translations.CONFIRM_SMS_CODE]:'Confirm SMS Code',
	[Translations.CONFIRM_TOTP_CODE]:'Confirm TOTP Code',
	[Translations.CONFIRM]:'ยืนยัน',
	[Translations.CREATE_ACCOUNT_TEXT]:'สร้างบัญชี',
	[Translations.EMAIL_LABEL]:'อีเมลล์ *',
	[Translations.EMAIL_PLACEHOLDER]:'ใส่อีเมลล์ของคุณ',
	[Translations.FORGOT_PASSWORD_TEXT]:'ลืมรหัสผ่าน?',
	[Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]:'Less than two MFA types available',
	[Translations.NEW_PASSWORD_LABEL]:'New password',
	[Translations.NEW_PASSWORD_PLACEHOLDER]:'Enter your new password',
	[Translations.NO_ACCOUNT_TEXT]:'ไม่มีบัญชีผู้ใช้?',
	[Translations.USERNAME_REMOVE_WHITESPACE]:'Username cannot contain whitespace',
	[Translations.PASSWORD_REMOVE_WHITESPACE]:'Password cannot start or end with whitespace',
	[Translations.PASSWORD_LABEL]:'รหัสผ่าน *',
	[Translations.PASSWORD_PLACEHOLDER]:'ใส่รหัสผ่านของคุณ',
	[Translations.PHONE_LABEL]:'Phone Number *',
	[Translations.PHONE_PLACEHOLDER]:'(555) 555-1212',
	[Translations.QR_CODE_ALT]:'qrcode',
	[Translations.RESET_PASSWORD_TEXT]:'รีเซ็ตรหัสผ่าน',
	[Translations.RESET_YOUR_PASSWORD]:'รีเซ็ตรหัสผ่าน',
	[Translations.SELECT_MFA_TYPE_HEADER_TEXT]:'Select MFA Type',
	[Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]:'Verify',
	[Translations.SEND_CODE]:'ส่งรหัสยืนยัน',
	[Translations.SUBMIT]:'Submit',
	[Translations.SETUP_TOTP_REQUIRED]:'TOTP needs to be configured',
	[Translations.SIGN_IN_ACTION]:'ลงชื่อเข้าใช้',
	[Translations.SIGN_IN_HEADER_TEXT]:'ลงชื่อเข้าใช้',
	[Translations.SIGN_IN_TEXT]:'ลงชื่อเข้าใช้',
	[Translations.SIGN_IN_WITH_AMAZON]:'Sign in with Amazon',
	[Translations.SIGN_IN_WITH_AUTH0]:'Sign in with Auth0',
	[Translations.SIGN_IN_WITH_AWS]:'Sign in with AWS',
	[Translations.SIGN_IN_WITH_FACEBOOK]:'Sign in with Facebook',
	[Translations.SIGN_IN_WITH_GOOGLE]:'Sign in with Google',
	[Translations.SIGN_OUT]:'Sign Out',
	[Translations.SIGN_UP_EMAIL_PLACEHOLDER]:'Email',
	[Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]:'มีบัญชีแล้ว?',
	[Translations.SIGN_UP_HEADER_TEXT]:'สร้างบัญชีผู้ใช้ใหม่',
	[Translations.SIGN_UP_PASSWORD_PLACEHOLDER]:'Password',
	[Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]:'สร้างบัญชีผู้ใช้',
	[Translations.SIGN_UP_USERNAME_PLACEHOLDER]:'Username',
	[Translations.SUCCESS_MFA_TYPE]:'Success! Your MFA Type is now]:',
	[Translations.TOTP_HEADER_TEXT]:'Scan then enter verification code',
	[Translations.TOTP_LABEL]:'Enter Security Code]:',
	[Translations.TOTP_ISSUER]:'AWSCognito',
	[Translations.TOTP_SETUP_FAILURE]:'TOTP Setup has failed',
	[Translations.TOTP_SUBMIT_BUTTON_TEXT]:'Verify Security Token',
	[Translations.TOTP_SUCCESS_MESSAGE]:'Setup TOTP successfully!',
	[Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]:'Failed! Unable to configure MFA at this time',
	[Translations.USERNAME_LABEL]:'ชื่อบัญชี *',
	[Translations.USERNAME_PLACEHOLDER]:'ใส่ชื่อบัญชีผู้ใช้ของคุณ',
	[Translations.VERIFY_CONTACT_EMAIL_LABEL]:'Email',
	[Translations.VERIFY_CONTACT_HEADER_TEXT]:'Account recovery requires verified contact information',
	[Translations.VERIFY_CONTACT_PHONE_LABEL]:'Phone Number',
	[Translations.VERIFY_CONTACT_SUBMIT_LABEL]:'Submit',
	[Translations.VERIFY_CONTACT_VERIFY_LABEL]:'Verify',
	[Translations.ADDRESS_LABEL]:'Address',
	[Translations.ADDRESS_PLACEHOLDER]:'Enter your address',
	[Translations.NICKNAME_LABEL]:'Nickname',
	[Translations.NICKNAME_PLACEHOLDER]:'Enter your nickname',
	[Translations.BIRTHDATE_LABEL]:'Birthday',
	[Translations.BIRTHDATE_PLACEHOLDER]:'Enter your birthday',
	[Translations.PICTURE_LABEL]:'Picture URL',
	[Translations.PICTURE_PLACEHOLDER]:'Enter your picture URL',
	[Translations.FAMILY_NAME_LABEL]:'Family Name',
	[Translations.FAMILY_NAME_PLACEHOLDER]:'Enter your family name',
	[Translations.PREFERRED_USERNAME_LABEL]:'Preferred Username',
	[Translations.PREFERRED_USERNAME_PLACEHOLDER]:'Enter your preferred username',
	[Translations.GENDER_LABEL]:'Gender',
	[Translations.GENDER_PLACEHOLDER]:'Enter your gender',
	[Translations.PROFILE_LABEL]:'Profile URL',
	[Translations.PROFILE_PLACEHOLDER]:'Enter your profile URL',
	[Translations.GIVEN_NAME_LABEL]:'First Name',
	[Translations.GIVEN_NAME_PLACEHOLDER]:'Enter your first name',
	[Translations.ZONEINFO_LABEL]:'Time zone',
	[Translations.ZONEINFO_PLACEHOLDER]:'Enter your time zone',
	[Translations.LOCALE_LABEL]:'Locale',
	[Translations.LOCALE_PLACEHOLDER]:'Enter your locale',
	[Translations.UPDATED_AT_LABEL]:'Updated At',
	[Translations.UPDATED_AT_PLACEHOLDER]:'Enter the time the information was last updated',
	[Translations.MIDDLE_NAME_LABEL]:'Middle Name',
	[Translations.MIDDLE_NAME_PLACEHOLDER]:'Enter your middle name',
	[Translations.WEBSITE_LABEL]:'Website',
	[Translations.WEBSITE_PLACEHOLDER]:'Enter your website',
	[Translations.NAME_LABEL]:'Full Name',
	[Translations.NAME_PLACEHOLDER]:'Enter your full name',
	[Translations.PHOTO_PICKER_TITLE]:'Picker Title',
	[Translations.PHOTO_PICKER_HINT]:'Ancillary text or content may occupy this space here',
	[Translations.PHOTO_PICKER_PLACEHOLDER_HINT]:'Placeholder hint',
	[Translations.PHOTO_PICKER_BUTTON_TEXT]:'Button',
	[Translations.IMAGE_PICKER_TITLE]:'Add Profile Photo',
	[Translations.IMAGE_PICKER_HINT]:'Preview the image before upload',
	[Translations.IMAGE_PICKER_PLACEHOLDER_HINT]:'Tap to image select',
	[Translations.IMAGE_PICKER_BUTTON_TEXT]:'Upload',
	[Translations.PICKER_TEXT]:'Pick a file',
	[Translations.TEXT_FALLBACK_CONTENT]:'Fallback Content',
	[Translations.CONFIRM_SIGN_UP_FAILED]:'Confirm Sign Up Failed',
	[Translations.SIGN_UP_FAILED]:'Sign Up Failed',
});

I18n.setLanguage("th-TH")
