import { API } from "aws-amplify";
import * as query from "@/graphql/queries";
import * as mutation from "@/graphql/mutations";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async createAlert(context: any, { userID, token, type, name }) {
        const response = await API.graphql({
            query: mutation.createAlert,
            variables: {
                input: {
                    userID,
                    type,
                    token,
                    name,
                    enable: true
                }
            }
        })
        return response;
    }
};

const alert = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default alert;
