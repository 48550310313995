import { API } from "aws-amplify";
import * as query from "@/graphql/queries";
import * as mutation from "@/graphql/mutations";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async hasOwner(context, { thingID }) {
        const query = `
            query GetThing($thingID: String!) {
                getThing(thingID: $thingID) {
                    users {
                        items {
                            userID
                            role
                        }
                    }
                }
            }
        `;

        const response: any = await API.graphql({
            query,
            variables: {
                thingID,
            },
        });

        console.log("response:", response);

        const users = response.data.getThing.users.items;

        return {
            hasOwner: users.some((i: any) => i.role === "OWNER"),
            items: users,
        };
    },
    async pair(context, { thingID, userID, role }) {
        const response: any = await API.graphql({
            query: mutation.createUserThing,
            variables: {
                input: {
                    thingID,
                    userID,
                    role: role.toUpperCase(),
                },
            },
        });

        console.log("response:", response);

        return response;
    },
    async requestAccess(context, { thingID, userID, username, role, ownerID }) {
        const response: any = await API.graphql({
            query: mutation.createSystemsMessage,
            variables: {
                input: {
                    title: "Request access",
                    message: `${username} Request access to ${thingID}`,
                    type: "ALERT",
                    userID: ownerID,
                    options: JSON.stringify(
                        {
                            type: "REQUESTACCESS",
                            payload: {
                                userID,
                                thingID,
                                role,
                            },
                        },
                    ),
                },
            },
        });

        console.log("response:", response);

        return response;
    },
};

const addDevice = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default addDevice;
