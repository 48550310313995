import { API, graphqlOperation } from "aws-amplify";
import * as query from "@/graphql/queries";
import * as mutation from "@/graphql/mutations";
import axios from "axios";

const DEVICE_CONFIGURATION_ENDPOINT =
  "https://raw.githubusercontent.com/AvilonRobotics/aviloniot-content/main/devices.json";

const service = {
  deviceList: [],
  getDeviceConfiguration: async function() {
    const response = await axios.get(DEVICE_CONFIGURATION_ENDPOINT);
    this.deviceList = response.data.deviceList;
    return response.data;
  },
  getDeviceUIComponent: function(type) {
    return this.deviceList.find((x) => x.type === type);
  },
  getThing: async function(thingID) {
    console.log("getThingName");
    const response = await API.graphql({
      query: query.getThing,
      variables: {
        thingID,
      },
    });
    console.log("getThingName", response);
    return response.data.getThing;
  },
  updateThingName: async function(thingID, name) {
    const response = await API.graphql({
      query: mutation.updateThing,
      variables: {
        input: {
          thingID: thingID,
          name: name,
        },
      },
    });
    const { data } = response;
    console.log("updateThingName", data);
  },
};

export default service;
