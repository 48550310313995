import store from "@/store";
import Auth from "@aws-amplify/auth";
import { AuthState } from "@aws-amplify/ui-components";
export const authenticationGuard = async (to, from, next) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
            console.log(user, to, from);
        }
        store.commit("auth/setAuthState", AuthState.SignedIn);
        store.commit("auth/setUser", user);
        return next();
    } catch (error) {
        return next("/auth");
    }
};
