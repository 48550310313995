import * as query from "@/customGraphql/queries";
import * as mutation from "@/graphql/mutations";
import { API } from "aws-amplify";

const state = {
    ruleList: [],
};

const getters = {
    ruleList: (state) => {
        return state.ruleList;
    },
};

const mutations = {
    setRuleList(state, ruleList) {
        state.ruleList = ruleList;
    },
    addRule(state, rule) {
        state.ruleList.push(rule);
    },
    removeRuleByID(state, id) {
        state.ruleList = state.ruleList.filter((item) => item.id != id);
    },
    // updateRuleList(state, newList) {
    //     state.ruleList = newList;
    // },
};

const actions = {
    async getRuleList(context, { userID }) {
        console.log("getRuleList")
        
        const response: any = await API.graphql({
            query: query.listThingTaskByUserId,
            variables: {
                userID,
                limit: 1000
            },
        });
        context.commit("setRuleList", response.data.listThingTaskByUserID.items);
    },
    async addRule(context, rule) {
        console.log(rule);

        const userID = context.rootState.user.user.id

        const task: any = await API.graphql({
            query: mutation.createThingTask,
            variables: {
                input: {
                    thingID: rule.thingID,
                    serverID: "test",
                    type: "ANOMALY",
                    topic: rule.topic,
                    userID: userID,
                    field: rule.field,
                    enable: true,
                    method: rule.method,
                    sensitivity: rule.sensitivity,
                    upperBound: rule.upperBound,
                    lowerBound: rule.lowerBound,
                    threshold: rule.threshold,
                },
            },
        });

        console.log(task.data.createThingTask);

        await Promise.all(
            rule.alerts.map(async (item: any) => {
                return API.graphql({
                    query: mutation.createThingTaskAlert,
                    variables: {
                        input: {
                            taskID: task.data.createThingTask.id,
                            alertID: item.id,
                        },
                    },
                });
            })
        );

        context.dispatch("getRuleList", {
            userID: userID,
        });
    },
    async enableRule(context, { id, enable }) {
        console.log("enableRule")
        
        const task: any = await API.graphql({
            query: mutation.updateThingTask,
            variables: {
                input: {
                    id,
                    enable,
                },
            },
        });
    },
    async removeRule(context, { id }) {
        console.log("removeRule");

        const task: any = await API.graphql({
            query: mutation.deleteThingTask,
            variables: {
                input: {
                    id,
                },
            },
        });

        context.commit("removeRuleByID", id);
    },
};

const anomalyDetectorTask = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default anomalyDetectorTask;
