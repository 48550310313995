import { API } from "aws-amplify";
import * as query from "@/graphql/queries";
import * as mutation from "@/graphql/mutations";

const state = {
    messages: [],
};

const getters = {
    messages: (state) => {
        return state.messages || [];
    },
};

const mutations = {
    setMessages(state, value) {
        state.messages = value;
    },
    removeByID(state, id){
        state.messages = state.messages.filter((item) => item.id != id);
    }
};

const actions = {
    async get(context, { userID }) {
        const response: any = await API.graphql({
            query: query.listSystemsMessagesByUserId,
            variables: {
                userID,
                sortDirection: "DESC"
            },
        });
        console.log("response:", response);
        const messages = response.data.listSystemsMessagesByUserID.items;
        context.commit("setMessages", messages);
    },
    async remove(context, {id}){
        const response: any = await API.graphql({
            query: mutation.deleteSystemsMessage,
            variables: {
                input: {
                    id
                },
            },
        });
        context.commit("removeByID", id);
    }
};

const systemsMessage = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default systemsMessage;
