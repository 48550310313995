import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import user from "@/store/modules/user";
import dashboard from "@/store/modules/dashboard";
import mq from "@/store/modules/mq";
import addDevice from "@/store/modules/addDevice";
import weatherForecast from "@/store/modules/weatherForecast";
import lineNotify from "@/store/modules/lineNotify";
import alert from "@/store/modules/alert";
import alertStream from "./modules/alertStream";
import timeseries from "@/store/modules/timeseries";
import systemsMessage from "@/store/modules/systemsMessage";
import anomalyDetectorTask from "./modules/anomalyDetectorTask";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        user,
        dashboard,
        mq,
        addDevice,
        weatherForecast,
        lineNotify,
        alert,
        alertStream,
        timeseries,
        systemsMessage,
        anomalyDetectorTask,
    },
});
