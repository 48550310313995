import { API } from "aws-amplify";
import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async getLineNotifyAccessToken(context: any, { code, redirect_uri }) {
        const response = await API.post("api", "/line/token", {
            body: {
                code,
                redirect_uri,
            },
        });
        return response.token;
    },
};

const lineNotify = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default lineNotify;
