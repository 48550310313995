import API from "@aws-amplify/api";
import dayjs from "dayjs";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async getLatestStream(context, { thingID, field }) {
        const response = await context.dispatch("getTimeseries", {
            thingID,
            field,
        });
        return {
            ...response,
            Point: response.Points
                ? response.Points.length > 0
                    ? response.Points[response.Points.length - 1]
                    : null
                : null,
        };
    },
    async getTimeseries(context, { thingID, field, start, end }) {
        console.log(thingID, field, start, end);
        const response = await API.get("api", "/timeseries/v3", {
            queryStringParameters: {
                thingID,
                field,
                start,
                end,
            },
        });
        return response;
    },
};

const timeseries = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default timeseries;
