/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      language
      weatherLocation {
        displayName
        lat
        lon
      }
      things {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      thingGroups {
        items {
          id
          userID
          thingGroupID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          themeColor
          userID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          input
          rule
          parameter
          description
          running
          userID
          output
          createdAt
          updatedAt
        }
        nextToken
      }
      alerts {
        items {
          id
          userID
          type
          name
          token
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      language
      weatherLocation {
        displayName
        lat
        lon
      }
      things {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      thingGroups {
        items {
          id
          userID
          thingGroupID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          themeColor
          userID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          input
          rule
          parameter
          description
          running
          userID
          output
          createdAt
          updatedAt
        }
        nextToken
      }
      alerts {
        items {
          id
          userID
          type
          name
          token
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      language
      weatherLocation {
        displayName
        lat
        lon
      }
      things {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      thingGroups {
        items {
          id
          userID
          thingGroupID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          themeColor
          userID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          input
          rule
          parameter
          description
          running
          userID
          output
          createdAt
          updatedAt
        }
        nextToken
      }
      alerts {
        items {
          id
          userID
          type
          name
          token
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserThing = /* GraphQL */ `
  mutation CreateUserThing(
    $input: CreateUserThingInput!
    $condition: ModelUserThingConditionInput
  ) {
    createUserThing(input: $input, condition: $condition) {
      id
      userID
      thingID
      role
      allowCSV
      createdAt
      updatedAt
    }
  }
`;
export const updateUserThing = /* GraphQL */ `
  mutation UpdateUserThing(
    $input: UpdateUserThingInput!
    $condition: ModelUserThingConditionInput
  ) {
    updateUserThing(input: $input, condition: $condition) {
      id
      userID
      thingID
      role
      allowCSV
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserThing = /* GraphQL */ `
  mutation DeleteUserThing(
    $input: DeleteUserThingInput!
    $condition: ModelUserThingConditionInput
  ) {
    deleteUserThing(input: $input, condition: $condition) {
      id
      userID
      thingID
      role
      allowCSV
      createdAt
      updatedAt
    }
  }
`;
export const createThing = /* GraphQL */ `
  mutation CreateThing(
    $input: CreateThingInput!
    $condition: ModelThingConditionInput
  ) {
    createThing(input: $input, condition: $condition) {
      thingID
      name
      expiredDate
      type
      serial
      description
      tags
      other
      users {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      console {
        thingID
        name
        createdAt
        updatedAt
      }
      aggregates {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      anomalyDetectors {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      credentials {
        items {
          username
          password
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateThing = /* GraphQL */ `
  mutation UpdateThing(
    $input: UpdateThingInput!
    $condition: ModelThingConditionInput
  ) {
    updateThing(input: $input, condition: $condition) {
      thingID
      name
      expiredDate
      type
      serial
      description
      tags
      other
      users {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      console {
        thingID
        name
        createdAt
        updatedAt
      }
      aggregates {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      anomalyDetectors {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      credentials {
        items {
          username
          password
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteThing = /* GraphQL */ `
  mutation DeleteThing(
    $input: DeleteThingInput!
    $condition: ModelThingConditionInput
  ) {
    deleteThing(input: $input, condition: $condition) {
      thingID
      name
      expiredDate
      type
      serial
      description
      tags
      other
      users {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      console {
        thingID
        name
        createdAt
        updatedAt
      }
      aggregates {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      anomalyDetectors {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      credentials {
        items {
          username
          password
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createThingStream = /* GraphQL */ `
  mutation CreateThingStream(
    $input: CreateThingStreamInput!
    $condition: ModelThingStreamConditionInput
  ) {
    createThingStream(input: $input, condition: $condition) {
      topic
      timestamp
      thingID
      fieldName
      mean
      count
      min
      max
      AggregatorID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const updateThingStream = /* GraphQL */ `
  mutation UpdateThingStream(
    $input: UpdateThingStreamInput!
    $condition: ModelThingStreamConditionInput
  ) {
    updateThingStream(input: $input, condition: $condition) {
      topic
      timestamp
      thingID
      fieldName
      mean
      count
      min
      max
      AggregatorID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteThingStream = /* GraphQL */ `
  mutation DeleteThingStream(
    $input: DeleteThingStreamInput!
    $condition: ModelThingStreamConditionInput
  ) {
    deleteThingStream(input: $input, condition: $condition) {
      topic
      timestamp
      thingID
      fieldName
      mean
      count
      min
      max
      AggregatorID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const createThingShadow = /* GraphQL */ `
  mutation CreateThingShadow(
    $input: CreateThingShadowInput!
    $condition: ModelThingShadowConditionInput
  ) {
    createThingShadow(input: $input, condition: $condition) {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      packetID
      createdAt
      updatedAt
    }
  }
`;
export const updateThingShadow = /* GraphQL */ `
  mutation UpdateThingShadow(
    $input: UpdateThingShadowInput!
    $condition: ModelThingShadowConditionInput
  ) {
    updateThingShadow(input: $input, condition: $condition) {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      packetID
      createdAt
      updatedAt
    }
  }
`;
export const deleteThingShadow = /* GraphQL */ `
  mutation DeleteThingShadow(
    $input: DeleteThingShadowInput!
    $condition: ModelThingShadowConditionInput
  ) {
    deleteThingShadow(input: $input, condition: $condition) {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      packetID
      createdAt
      updatedAt
    }
  }
`;
export const createThingShadowHistory = /* GraphQL */ `
  mutation CreateThingShadowHistory(
    $input: CreateThingShadowHistoryInput!
    $condition: ModelThingShadowHistoryConditionInput
  ) {
    createThingShadowHistory(input: $input, condition: $condition) {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      action
      packetID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const updateThingShadowHistory = /* GraphQL */ `
  mutation UpdateThingShadowHistory(
    $input: UpdateThingShadowHistoryInput!
    $condition: ModelThingShadowHistoryConditionInput
  ) {
    updateThingShadowHistory(input: $input, condition: $condition) {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      action
      packetID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteThingShadowHistory = /* GraphQL */ `
  mutation DeleteThingShadowHistory(
    $input: DeleteThingShadowHistoryInput!
    $condition: ModelThingShadowHistoryConditionInput
  ) {
    deleteThingShadowHistory(input: $input, condition: $condition) {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      action
      packetID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const createThingCredentials = /* GraphQL */ `
  mutation CreateThingCredentials(
    $input: CreateThingCredentialsInput!
    $condition: ModelThingCredentialsConditionInput
  ) {
    createThingCredentials(input: $input, condition: $condition) {
      username
      password
      thingID
      createdAt
      updatedAt
    }
  }
`;
export const updateThingCredentials = /* GraphQL */ `
  mutation UpdateThingCredentials(
    $input: UpdateThingCredentialsInput!
    $condition: ModelThingCredentialsConditionInput
  ) {
    updateThingCredentials(input: $input, condition: $condition) {
      username
      password
      thingID
      createdAt
      updatedAt
    }
  }
`;
export const deleteThingCredentials = /* GraphQL */ `
  mutation DeleteThingCredentials(
    $input: DeleteThingCredentialsInput!
    $condition: ModelThingCredentialsConditionInput
  ) {
    deleteThingCredentials(input: $input, condition: $condition) {
      username
      password
      thingID
      createdAt
      updatedAt
    }
  }
`;
export const createThingConsole = /* GraphQL */ `
  mutation CreateThingConsole(
    $input: CreateThingConsoleInput!
    $condition: ModelThingConsoleConditionInput
  ) {
    createThingConsole(input: $input, condition: $condition) {
      thingID
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateThingConsole = /* GraphQL */ `
  mutation UpdateThingConsole(
    $input: UpdateThingConsoleInput!
    $condition: ModelThingConsoleConditionInput
  ) {
    updateThingConsole(input: $input, condition: $condition) {
      thingID
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteThingConsole = /* GraphQL */ `
  mutation DeleteThingConsole(
    $input: DeleteThingConsoleInput!
    $condition: ModelThingConsoleConditionInput
  ) {
    deleteThingConsole(input: $input, condition: $condition) {
      thingID
      name
      createdAt
      updatedAt
    }
  }
`;
export const createAggregator = /* GraphQL */ `
  mutation CreateAggregator(
    $input: CreateAggregatorInput!
    $condition: ModelAggregatorConditionInput
  ) {
    createAggregator(input: $input, condition: $condition) {
      id
      tasks {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAggregator = /* GraphQL */ `
  mutation UpdateAggregator(
    $input: UpdateAggregatorInput!
    $condition: ModelAggregatorConditionInput
  ) {
    updateAggregator(input: $input, condition: $condition) {
      id
      tasks {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAggregator = /* GraphQL */ `
  mutation DeleteAggregator(
    $input: DeleteAggregatorInput!
    $condition: ModelAggregatorConditionInput
  ) {
    deleteAggregator(input: $input, condition: $condition) {
      id
      tasks {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAggregateTask = /* GraphQL */ `
  mutation CreateAggregateTask(
    $input: CreateAggregateTaskInput!
    $condition: ModelAggregateTaskConditionInput
  ) {
    createAggregateTask(input: $input, condition: $condition) {
      id
      thingID
      AggregatorID
      enable
      topics {
        items {
          id
          AggregateTaskID
          topic
          createdAt
          updatedAt
        }
        nextToken
      }
      detectedFields {
        items {
          id
          AggregateTaskID
          field
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAggregateTask = /* GraphQL */ `
  mutation UpdateAggregateTask(
    $input: UpdateAggregateTaskInput!
    $condition: ModelAggregateTaskConditionInput
  ) {
    updateAggregateTask(input: $input, condition: $condition) {
      id
      thingID
      AggregatorID
      enable
      topics {
        items {
          id
          AggregateTaskID
          topic
          createdAt
          updatedAt
        }
        nextToken
      }
      detectedFields {
        items {
          id
          AggregateTaskID
          field
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAggregateTask = /* GraphQL */ `
  mutation DeleteAggregateTask(
    $input: DeleteAggregateTaskInput!
    $condition: ModelAggregateTaskConditionInput
  ) {
    deleteAggregateTask(input: $input, condition: $condition) {
      id
      thingID
      AggregatorID
      enable
      topics {
        items {
          id
          AggregateTaskID
          topic
          createdAt
          updatedAt
        }
        nextToken
      }
      detectedFields {
        items {
          id
          AggregateTaskID
          field
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAggregateTaskTopic = /* GraphQL */ `
  mutation CreateAggregateTaskTopic(
    $input: CreateAggregateTaskTopicInput!
    $condition: ModelAggregateTaskTopicConditionInput
  ) {
    createAggregateTaskTopic(input: $input, condition: $condition) {
      id
      AggregateTaskID
      topic
      createdAt
      updatedAt
    }
  }
`;
export const updateAggregateTaskTopic = /* GraphQL */ `
  mutation UpdateAggregateTaskTopic(
    $input: UpdateAggregateTaskTopicInput!
    $condition: ModelAggregateTaskTopicConditionInput
  ) {
    updateAggregateTaskTopic(input: $input, condition: $condition) {
      id
      AggregateTaskID
      topic
      createdAt
      updatedAt
    }
  }
`;
export const deleteAggregateTaskTopic = /* GraphQL */ `
  mutation DeleteAggregateTaskTopic(
    $input: DeleteAggregateTaskTopicInput!
    $condition: ModelAggregateTaskTopicConditionInput
  ) {
    deleteAggregateTaskTopic(input: $input, condition: $condition) {
      id
      AggregateTaskID
      topic
      createdAt
      updatedAt
    }
  }
`;
export const createAggregateTaskDetectedField = /* GraphQL */ `
  mutation CreateAggregateTaskDetectedField(
    $input: CreateAggregateTaskDetectedFieldInput!
    $condition: ModelAggregateTaskDetectedFieldConditionInput
  ) {
    createAggregateTaskDetectedField(input: $input, condition: $condition) {
      id
      AggregateTaskID
      field
      createdAt
      updatedAt
    }
  }
`;
export const updateAggregateTaskDetectedField = /* GraphQL */ `
  mutation UpdateAggregateTaskDetectedField(
    $input: UpdateAggregateTaskDetectedFieldInput!
    $condition: ModelAggregateTaskDetectedFieldConditionInput
  ) {
    updateAggregateTaskDetectedField(input: $input, condition: $condition) {
      id
      AggregateTaskID
      field
      createdAt
      updatedAt
    }
  }
`;
export const deleteAggregateTaskDetectedField = /* GraphQL */ `
  mutation DeleteAggregateTaskDetectedField(
    $input: DeleteAggregateTaskDetectedFieldInput!
    $condition: ModelAggregateTaskDetectedFieldConditionInput
  ) {
    deleteAggregateTaskDetectedField(input: $input, condition: $condition) {
      id
      AggregateTaskID
      field
      createdAt
      updatedAt
    }
  }
`;
export const createAnomalyDetector = /* GraphQL */ `
  mutation CreateAnomalyDetector(
    $input: CreateAnomalyDetectorInput!
    $condition: ModelAnomalyDetectorConditionInput
  ) {
    createAnomalyDetector(input: $input, condition: $condition) {
      id
      tasks {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAnomalyDetector = /* GraphQL */ `
  mutation UpdateAnomalyDetector(
    $input: UpdateAnomalyDetectorInput!
    $condition: ModelAnomalyDetectorConditionInput
  ) {
    updateAnomalyDetector(input: $input, condition: $condition) {
      id
      tasks {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnomalyDetector = /* GraphQL */ `
  mutation DeleteAnomalyDetector(
    $input: DeleteAnomalyDetectorInput!
    $condition: ModelAnomalyDetectorConditionInput
  ) {
    deleteAnomalyDetector(input: $input, condition: $condition) {
      id
      tasks {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAnomalyDetectorTask = /* GraphQL */ `
  mutation CreateAnomalyDetectorTask(
    $input: CreateAnomalyDetectorTaskInput!
    $condition: ModelAnomalyDetectorTaskConditionInput
  ) {
    createAnomalyDetectorTask(input: $input, condition: $condition) {
      id
      thingID
      AnomalyDetectorID
      topic
      field
      enable
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAnomalyDetectorTask = /* GraphQL */ `
  mutation UpdateAnomalyDetectorTask(
    $input: UpdateAnomalyDetectorTaskInput!
    $condition: ModelAnomalyDetectorTaskConditionInput
  ) {
    updateAnomalyDetectorTask(input: $input, condition: $condition) {
      id
      thingID
      AnomalyDetectorID
      topic
      field
      enable
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnomalyDetectorTask = /* GraphQL */ `
  mutation DeleteAnomalyDetectorTask(
    $input: DeleteAnomalyDetectorTaskInput!
    $condition: ModelAnomalyDetectorTaskConditionInput
  ) {
    deleteAnomalyDetectorTask(input: $input, condition: $condition) {
      id
      thingID
      AnomalyDetectorID
      topic
      field
      enable
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAlertStream = /* GraphQL */ `
  mutation CreateAlertStream(
    $input: CreateAlertStreamInput!
    $condition: ModelAlertStreamConditionInput
  ) {
    createAlertStream(input: $input, condition: $condition) {
      id
      type
      creatorID
      thingID
      topic
      field
      value
      userID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const updateAlertStream = /* GraphQL */ `
  mutation UpdateAlertStream(
    $input: UpdateAlertStreamInput!
    $condition: ModelAlertStreamConditionInput
  ) {
    updateAlertStream(input: $input, condition: $condition) {
      id
      type
      creatorID
      thingID
      topic
      field
      value
      userID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlertStream = /* GraphQL */ `
  mutation DeleteAlertStream(
    $input: DeleteAlertStreamInput!
    $condition: ModelAlertStreamConditionInput
  ) {
    deleteAlertStream(input: $input, condition: $condition) {
      id
      type
      creatorID
      thingID
      topic
      field
      value
      userID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const createAnomalyDetectorTaskAlert = /* GraphQL */ `
  mutation CreateAnomalyDetectorTaskAlert(
    $input: CreateAnomalyDetectorTaskAlertInput!
    $condition: ModelAnomalyDetectorTaskAlertConditionInput
  ) {
    createAnomalyDetectorTaskAlert(input: $input, condition: $condition) {
      id
      taskID
      task {
        id
        thingID
        AnomalyDetectorID
        topic
        field
        enable
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        alerts {
          nextToken
        }
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAnomalyDetectorTaskAlert = /* GraphQL */ `
  mutation UpdateAnomalyDetectorTaskAlert(
    $input: UpdateAnomalyDetectorTaskAlertInput!
    $condition: ModelAnomalyDetectorTaskAlertConditionInput
  ) {
    updateAnomalyDetectorTaskAlert(input: $input, condition: $condition) {
      id
      taskID
      task {
        id
        thingID
        AnomalyDetectorID
        topic
        field
        enable
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        alerts {
          nextToken
        }
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnomalyDetectorTaskAlert = /* GraphQL */ `
  mutation DeleteAnomalyDetectorTaskAlert(
    $input: DeleteAnomalyDetectorTaskAlertInput!
    $condition: ModelAnomalyDetectorTaskAlertConditionInput
  ) {
    deleteAnomalyDetectorTaskAlert(input: $input, condition: $condition) {
      id
      taskID
      task {
        id
        thingID
        AnomalyDetectorID
        topic
        field
        enable
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        alerts {
          nextToken
        }
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAlert = /* GraphQL */ `
  mutation CreateAlert(
    $input: CreateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    createAlert(input: $input, condition: $condition) {
      id
      userID
      type
      name
      token
      enable
      tasks {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert(
    $input: UpdateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    updateAlert(input: $input, condition: $condition) {
      id
      userID
      type
      name
      token
      enable
      tasks {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlert = /* GraphQL */ `
  mutation DeleteAlert(
    $input: DeleteAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    deleteAlert(input: $input, condition: $condition) {
      id
      userID
      type
      name
      token
      enable
      tasks {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createShadowDispatcher = /* GraphQL */ `
  mutation CreateShadowDispatcher(
    $input: CreateShadowDispatcherInput!
    $condition: ModelShadowDispatcherConditionInput
  ) {
    createShadowDispatcher(input: $input, condition: $condition) {
      id
      things {
        items {
          id
          thingID
          ShadowDispatcherID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateShadowDispatcher = /* GraphQL */ `
  mutation UpdateShadowDispatcher(
    $input: UpdateShadowDispatcherInput!
    $condition: ModelShadowDispatcherConditionInput
  ) {
    updateShadowDispatcher(input: $input, condition: $condition) {
      id
      things {
        items {
          id
          thingID
          ShadowDispatcherID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteShadowDispatcher = /* GraphQL */ `
  mutation DeleteShadowDispatcher(
    $input: DeleteShadowDispatcherInput!
    $condition: ModelShadowDispatcherConditionInput
  ) {
    deleteShadowDispatcher(input: $input, condition: $condition) {
      id
      things {
        items {
          id
          thingID
          ShadowDispatcherID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createShadowDispatcherThing = /* GraphQL */ `
  mutation CreateShadowDispatcherThing(
    $input: CreateShadowDispatcherThingInput!
    $condition: ModelShadowDispatcherThingConditionInput
  ) {
    createShadowDispatcherThing(input: $input, condition: $condition) {
      id
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      ShadowDispatcherID
      createdAt
      updatedAt
    }
  }
`;
export const updateShadowDispatcherThing = /* GraphQL */ `
  mutation UpdateShadowDispatcherThing(
    $input: UpdateShadowDispatcherThingInput!
    $condition: ModelShadowDispatcherThingConditionInput
  ) {
    updateShadowDispatcherThing(input: $input, condition: $condition) {
      id
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      ShadowDispatcherID
      createdAt
      updatedAt
    }
  }
`;
export const deleteShadowDispatcherThing = /* GraphQL */ `
  mutation DeleteShadowDispatcherThing(
    $input: DeleteShadowDispatcherThingInput!
    $condition: ModelShadowDispatcherThingConditionInput
  ) {
    deleteShadowDispatcherThing(input: $input, condition: $condition) {
      id
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      ShadowDispatcherID
      createdAt
      updatedAt
    }
  }
`;
export const createDashboard = /* GraphQL */ `
  mutation CreateDashboard(
    $input: CreateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    createDashboard(input: $input, condition: $condition) {
      id
      name
      themeColor
      dashboardItems {
        items {
          id
          name
          x
          y
          width
          height
          type
          description
          option
          timeSeriesSource
          realtimeSource
          dashboardID
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateDashboard = /* GraphQL */ `
  mutation UpdateDashboard(
    $input: UpdateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    updateDashboard(input: $input, condition: $condition) {
      id
      name
      themeColor
      dashboardItems {
        items {
          id
          name
          x
          y
          width
          height
          type
          description
          option
          timeSeriesSource
          realtimeSource
          dashboardID
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteDashboard = /* GraphQL */ `
  mutation DeleteDashboard(
    $input: DeleteDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    deleteDashboard(input: $input, condition: $condition) {
      id
      name
      themeColor
      dashboardItems {
        items {
          id
          name
          x
          y
          width
          height
          type
          description
          option
          timeSeriesSource
          realtimeSource
          dashboardID
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      type
      createdAt
      updatedAt
    }
  }
`;
export const createDashboardItem = /* GraphQL */ `
  mutation CreateDashboardItem(
    $input: CreateDashboardItemInput!
    $condition: ModelDashboardItemConditionInput
  ) {
    createDashboardItem(input: $input, condition: $condition) {
      id
      name
      x
      y
      width
      height
      type
      description
      option
      timeSeriesSource
      realtimeSource
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const updateDashboardItem = /* GraphQL */ `
  mutation UpdateDashboardItem(
    $input: UpdateDashboardItemInput!
    $condition: ModelDashboardItemConditionInput
  ) {
    updateDashboardItem(input: $input, condition: $condition) {
      id
      name
      x
      y
      width
      height
      type
      description
      option
      timeSeriesSource
      realtimeSource
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const deleteDashboardItem = /* GraphQL */ `
  mutation DeleteDashboardItem(
    $input: DeleteDashboardItemInput!
    $condition: ModelDashboardItemConditionInput
  ) {
    deleteDashboardItem(input: $input, condition: $condition) {
      id
      name
      x
      y
      width
      height
      type
      description
      option
      timeSeriesSource
      realtimeSource
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const createRule = /* GraphQL */ `
  mutation CreateRule(
    $input: CreateRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    createRule(input: $input, condition: $condition) {
      id
      name
      type
      input
      rule
      parameter
      description
      running
      userID
      output
      createdAt
      updatedAt
    }
  }
`;
export const updateRule = /* GraphQL */ `
  mutation UpdateRule(
    $input: UpdateRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    updateRule(input: $input, condition: $condition) {
      id
      name
      type
      input
      rule
      parameter
      description
      running
      userID
      output
      createdAt
      updatedAt
    }
  }
`;
export const deleteRule = /* GraphQL */ `
  mutation DeleteRule(
    $input: DeleteRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    deleteRule(input: $input, condition: $condition) {
      id
      name
      type
      input
      rule
      parameter
      description
      running
      userID
      output
      createdAt
      updatedAt
    }
  }
`;
export const createSystemsMessage = /* GraphQL */ `
  mutation CreateSystemsMessage(
    $input: CreateSystemsMessageInput!
    $condition: ModelSystemsMessageConditionInput
  ) {
    createSystemsMessage(input: $input, condition: $condition) {
      id
      createdAt
      type
      title
      userID
      message
      status
      options
      updatedAt
    }
  }
`;
export const updateSystemsMessage = /* GraphQL */ `
  mutation UpdateSystemsMessage(
    $input: UpdateSystemsMessageInput!
    $condition: ModelSystemsMessageConditionInput
  ) {
    updateSystemsMessage(input: $input, condition: $condition) {
      id
      createdAt
      type
      title
      userID
      message
      status
      options
      updatedAt
    }
  }
`;
export const deleteSystemsMessage = /* GraphQL */ `
  mutation DeleteSystemsMessage(
    $input: DeleteSystemsMessageInput!
    $condition: ModelSystemsMessageConditionInput
  ) {
    deleteSystemsMessage(input: $input, condition: $condition) {
      id
      createdAt
      type
      title
      userID
      message
      status
      options
      updatedAt
    }
  }
`;
export const createUserThingGroup = /* GraphQL */ `
  mutation CreateUserThingGroup(
    $input: CreateUserThingGroupInput!
    $condition: ModelUserThingGroupConditionInput
  ) {
    createUserThingGroup(input: $input, condition: $condition) {
      id
      userID
      thingGroupID
      thingGroup {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      role
      createdAt
      updatedAt
    }
  }
`;
export const updateUserThingGroup = /* GraphQL */ `
  mutation UpdateUserThingGroup(
    $input: UpdateUserThingGroupInput!
    $condition: ModelUserThingGroupConditionInput
  ) {
    updateUserThingGroup(input: $input, condition: $condition) {
      id
      userID
      thingGroupID
      thingGroup {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      role
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserThingGroup = /* GraphQL */ `
  mutation DeleteUserThingGroup(
    $input: DeleteUserThingGroupInput!
    $condition: ModelUserThingGroupConditionInput
  ) {
    deleteUserThingGroup(input: $input, condition: $condition) {
      id
      userID
      thingGroupID
      thingGroup {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      role
      createdAt
      updatedAt
    }
  }
`;
export const createThingGroup = /* GraphQL */ `
  mutation CreateThingGroup(
    $input: CreateThingGroupInput!
    $condition: ModelThingGroupConditionInput
  ) {
    createThingGroup(input: $input, condition: $condition) {
      id
      name
      things {
        items {
          id
          thingGroupID
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateThingGroup = /* GraphQL */ `
  mutation UpdateThingGroup(
    $input: UpdateThingGroupInput!
    $condition: ModelThingGroupConditionInput
  ) {
    updateThingGroup(input: $input, condition: $condition) {
      id
      name
      things {
        items {
          id
          thingGroupID
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteThingGroup = /* GraphQL */ `
  mutation DeleteThingGroup(
    $input: DeleteThingGroupInput!
    $condition: ModelThingGroupConditionInput
  ) {
    deleteThingGroup(input: $input, condition: $condition) {
      id
      name
      things {
        items {
          id
          thingGroupID
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createThingGroupThing = /* GraphQL */ `
  mutation CreateThingGroupThing(
    $input: CreateThingGroupThingInput!
    $condition: ModelThingGroupThingConditionInput
  ) {
    createThingGroupThing(input: $input, condition: $condition) {
      id
      thingGroupID
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateThingGroupThing = /* GraphQL */ `
  mutation UpdateThingGroupThing(
    $input: UpdateThingGroupThingInput!
    $condition: ModelThingGroupThingConditionInput
  ) {
    updateThingGroupThing(input: $input, condition: $condition) {
      id
      thingGroupID
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteThingGroupThing = /* GraphQL */ `
  mutation DeleteThingGroupThing(
    $input: DeleteThingGroupThingInput!
    $condition: ModelThingGroupThingConditionInput
  ) {
    deleteThingGroupThing(input: $input, condition: $condition) {
      id
      thingGroupID
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createThingTimeseries = /* GraphQL */ `
  mutation CreateThingTimeseries(
    $input: CreateThingTimeseriesInput!
    $condition: ModelThingTimeseriesConditionInput
  ) {
    createThingTimeseries(input: $input, condition: $condition) {
      thingID
      timestamp
      fieldName
      mean
      count
      min
      max
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const updateThingTimeseries = /* GraphQL */ `
  mutation UpdateThingTimeseries(
    $input: UpdateThingTimeseriesInput!
    $condition: ModelThingTimeseriesConditionInput
  ) {
    updateThingTimeseries(input: $input, condition: $condition) {
      thingID
      timestamp
      fieldName
      mean
      count
      min
      max
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteThingTimeseries = /* GraphQL */ `
  mutation DeleteThingTimeseries(
    $input: DeleteThingTimeseriesInput!
    $condition: ModelThingTimeseriesConditionInput
  ) {
    deleteThingTimeseries(input: $input, condition: $condition) {
      thingID
      timestamp
      fieldName
      mean
      count
      min
      max
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const createThingTask = /* GraphQL */ `
  mutation CreateThingTask(
    $input: CreateThingTaskInput!
    $condition: ModelThingTaskConditionInput
  ) {
    createThingTask(input: $input, condition: $condition) {
      id
      serverID
      type
      enable
      thingID
      userID
      topic
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      field
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      createdAt
      updatedAt
    }
  }
`;
export const updateThingTask = /* GraphQL */ `
  mutation UpdateThingTask(
    $input: UpdateThingTaskInput!
    $condition: ModelThingTaskConditionInput
  ) {
    updateThingTask(input: $input, condition: $condition) {
      id
      serverID
      type
      enable
      thingID
      userID
      topic
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      field
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      createdAt
      updatedAt
    }
  }
`;
export const deleteThingTask = /* GraphQL */ `
  mutation DeleteThingTask(
    $input: DeleteThingTaskInput!
    $condition: ModelThingTaskConditionInput
  ) {
    deleteThingTask(input: $input, condition: $condition) {
      id
      serverID
      type
      enable
      thingID
      userID
      topic
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      field
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      createdAt
      updatedAt
    }
  }
`;
export const createThingTaskAlert = /* GraphQL */ `
  mutation CreateThingTaskAlert(
    $input: CreateThingTaskAlertInput!
    $condition: ModelThingTaskAlertConditionInput
  ) {
    createThingTaskAlert(input: $input, condition: $condition) {
      id
      taskID
      task {
        id
        serverID
        type
        enable
        thingID
        userID
        topic
        alerts {
          nextToken
        }
        field
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateThingTaskAlert = /* GraphQL */ `
  mutation UpdateThingTaskAlert(
    $input: UpdateThingTaskAlertInput!
    $condition: ModelThingTaskAlertConditionInput
  ) {
    updateThingTaskAlert(input: $input, condition: $condition) {
      id
      taskID
      task {
        id
        serverID
        type
        enable
        thingID
        userID
        topic
        alerts {
          nextToken
        }
        field
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteThingTaskAlert = /* GraphQL */ `
  mutation DeleteThingTaskAlert(
    $input: DeleteThingTaskAlertInput!
    $condition: ModelThingTaskAlertConditionInput
  ) {
    deleteThingTaskAlert(input: $input, condition: $condition) {
      id
      taskID
      task {
        id
        serverID
        type
        enable
        thingID
        userID
        topic
        alerts {
          nextToken
        }
        field
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
